/* .card{
    padding: 0 !important;
} */
.wish-icon{
    display: flex;
    
    position: absolute;
    top: 15px;
    left: 300px;
}
.buttton1{
    background-color: #F7941D;
    color: white;
    border: none;
    border-radius: 0.2rem;
}

.screen-size-60{
    width: 60%;
}
.screen-size-40{
    width: 40%;
    height: 20%;
}