.line {
    border-bottom: 1px solid #d7d3d3;
  }
.poppins{
    font-family: poppins;
    font-weight: 400;
    
}
.photos-size{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}
