.whatlooking  {
    padding: 2rem;
}
.whatlooking .msg1{
    color: #1F53B9;
    font-size: 2rem;
}
.whatlooking .msg2{
    color: black;
    font-size: 1rem;
    
}
.row-gap{
    row-gap: 1rem;
}
.column-gap{
    column-gap: 1rem;
}
/* .apartment{
    background-image: url(../../assets/image/whatlooking/img1.png);
    height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    color: aliceblue;
    
} */