.nav-container{
    display: flex;
    flex-direction: column;
}
.navBox {
   display: flex;
}
.navbar{
    padding-inline: 17%;
    height: 70px;
}
.bg-white {
    background: #0ec5ea;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
}
li { 
    padding-right: 5%;
    padding-left: 5%;  
  }
/* .nav-menu-text{ 
    font-family: 'poppins';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    display: flex;
    align-items: center;   
    color:rgba(0, 0, 0, 1); 
} */
.menu-home {
    font-family: 'poppins';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    display: flex;
    align-items: center;   
    color:rgba(0, 0, 0, 1);
}
.menu-home:hover{
    color: rgba(31, 83, 185, 1) !important;
}
.menu-home::current{
    color: #0ec5ea;
}

.btn1{
    background: #1F53B9!important;
    color: aliceblue;
    padding: 2px 10px;
}
.signup-btn{
    font-family: 'poppins';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    border: none;
    background: rgba(31, 83, 185, 1);
    color: rgba(255, 255, 255, 1) ;
    padding-block: 0.3rem;
    padding-inline: 1rem;
    border-radius: 0.3rem;
}
.login{
    border: none;
    background: #ffffff;
    padding: 2px 10px;
    padding-right: 1rem;
    padding-left: 1rem;
}
/* .nav-button:hover > .login {
    color:  #1F53B9;
    border: 1px solid #0ec5ea;
    border-radius: 0.3rem;
} */
.nav-button{
    display: flex;
    flex-direction: row;  
}

