.filter-content{
     background-image: url(../../assets/image/Others/filterBg.png);
     background-size: 100% 100%;
     background-repeat: no-repeat;   
}
.filter-margin{
    margin-top: 10rem;
    margin-right: 10rem; 
    margin-bottom: 15rem;
    margin-left: 10rem;
}
.box-bg{
    background: rgba(31, 83, 185, 0.1); ;
    backdrop-filter: blur(10px);
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 10px;   
    
}
.filter-tab-btn {
    padding: 0.8rem 2.4rem;
    color: #F7941D;
    background-color: #ffffff;
    font-weight: 500px;
    transition: var(--transition);
    border: none;
    transition-timing-function: linear;
  }
.filter-tab-btn.active {
    color: white;
    background-color: #F7941D;
  }
  .filter-tab-btn.search {
    color: white;
    background-color: #F7941D;
  }

