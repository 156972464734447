@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");


       /* body{
        background-color:#535fe6;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
       } */

       .height{
        height: 100vh;
       }
       .card{
        border:none;
        padding: 20px;
        background-color: #ffffff;
        color: #1F53B9;

       }
       .padding{
        padding: 2rem 5rem 3rem 5rem;
       }
       /* .imglogo{
        display: flex;
        width: auto;
        height: 100%;
       } */

       /* .circle{

        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:#5855e7;
        color: #fff;
        font-size: 10px;
        border-radius: 50%;
       } */

       .form-input{
        position: relative;
        margin-bottom: 0.5rem;
        margin-top:0.5rem;      
       }

       .form-input i{
            position: absolute;
            font-size: 18px;
            top: 15px;
            left: 10px;        
       }

       .form-control{
        height: 3.2rem;
        background-color: #fff;
        text-indent: 24px;
        font-size: 15px;
       }

       /* .form-control:focus{

        background-color: #ffffff;
        box-shadow: none;
        color: #000000;
        border-color: #4f63e7;
       }

       .form-check-label{
        margin-top: 2px;
        font-size: 14px;
       } */

        /* .signup{
          height: 50px;
          font-size: 14px;
        } */

