
.h6{
    width:40%;
    margin-top:-10px;
    margin-left:15px;
    text-align: center;
    background-color: #F4F7FD;
    display: flex;
    justify-content: center;
    flex: row;
}
.placeholder-margin{   
        padding-left: 5%;
        padding-right: 5%;
        padding-top: -5%;
        padding-bottom: 3%;
   
}
input:focus, textarea:focus, select:focus{
        outline: none;
    }
    .button-tab .active{
        color: #fff;
        background-color: #1F53B9;
    }
.hover-btn:hover  {
        color: #fff !important ;
        background-color: #1F53B9;
    }
.profile-tab-btn.search {
     color: white;
    background-color: #1F53B9;
    border: none;
 }