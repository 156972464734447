.mt-5 {
    margin-top: 5rem!important;
}
.body-content{
    height:86vh;
    background-image: url(../../assets/image/Home/BG.png);
    background-size: 100% 100%;
    padding-bottom: 0;
    background-repeat: no-repeat;
    margin-left: 90px;
    margin-right: 90px;
    margin-top: 70px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.fyd{
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    text-align: center;
    color: #FFFFFF;
}
.paragraph{
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}
.lg-screen-search{
    display: block;
}
.sm-screen-search{
    display: none;
    /* padding: 1rem; */
}
.poppins{
    font-family: 'poppins'!important;
}
@media only screen and (max-width: 375px)  {
    .navbar{
        padding: 3%;
    }
    .mt-5 {
        margin-top: 2rem!important;
    }
    .body-content{
        width: 100%;
        height: 60vh;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 70px !important;
        background-image: url(../../assets/image/Home/BG.png);
        background-size: 100% 100%;
        border-radius: 0px !important;
        display: flex;
        justify-content: normal;

    }
    .fyd-parent{
        margin-top: 0 !important;
    }
    .fyd{
        font-family: 'poppins';
        font-style: normal;
        font-weight: 800;
        font-size: 35px !important;
        line-height: 40px;
        text-align: left !important;
        padding-left: 3%;
        padding-right: 20%;
        color: #FFFFFF;
    }
    .paragraph{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 13px !important;
        line-height: 20px !important;
        text-align: center ;
        padding-left: 3%;
        padding-right: 5%;
        display: flex;
        /* align-items: flex-start; */
        text-align: left !important;
        color: #FFFFFF;
    }
    .lg-screen-search{
        display: none;
    }
    .sm-screen-search{
        display: block;
        padding: 0.3rem 0.3rem;
    }
    .log-button{
        display: flex;
        justify-content: space-evenly;
    }
    .btn1 {
        background: transparent!important;
        color: #FFFFFF;
        border: 0.1rem solid #FFFFFF;
        border-radius: 0.3rem;
        padding: 2px 20px;
    }
    .btn2 {
        background: #1F53B9;
        color: #FFFFFF;
        border: 0.1rem solid #FFFFFF;
        border-radius: 0.3rem;
        padding: 2px 20px;
    }
    .hero-form-wrapper {
        margin-block-start: -50px!important;
        width: 89%!important;
      }
      .form-tab {
        background: #ffffff;
        display: flex;
        justify-content: center;
        /* padding-top: 0%; */
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
      .tab-btn {
        padding: 0.5rem 2.4rem!important;
      }
      .form-tab:hover > .tab-btn {
        color: #1F53B9;
      }
      /* .tab-btn:is(:hover, :focus) { color: #1F53B9; } */
      
      .tab-btn.active {
        color: white;
        background-color: #1F53B9;
      }
      
      .hero-form {
        width: 100%!important;
        display: flex!important;
        flex-direction: column!important;
        justify-content: center!important;
        align-items: center !important;
        gap: 25px!important;
        background: #ffffff!important;
        padding: 25px!important;
        border-radius: 0rem !important;
        
      }
      
      .hero-form .input-wrapper { 
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     } 
      .hero-form :is(.input-label, .input-field) { color: var(--oxford-blue); }
      
      .hero-form .input-label {
        font-weight: bold!important;
    margin-block-end: 1px!important;
      }
      .h3, h3 {
        font-size: calc(1rem + .6vw);
    }
    .option{
        font-size: 2px!important;
    } 
      .hero-form :is(.input-field, .dropdown-list) {
        min-height: 0px!important;
        border: 1px solid #bcbcbc!important;
        border-radius: 0.1rem!important;
        outline: none; 
      }
      
      .hero-form :is(.input-field, .dropdown-list):focus { border-color: var(--green-pigment); }
      
      .hero-form .input-field { padding-inline: 45px 15px; }
      
      .hero-form .input-field::placeholder { color: var(--manatee); }
      
      .hero-form .dropdown-list { padding-inline: 15px; }
      
      .hero-form .btn { max-width: 100%; }

      .whatlooking {
        padding: 2rem 0rem !important;
        
    }
    .home-margin{
        margin-left: 0 !important;
        margin-right: 0 !important;
        
    }
    .padding{
        padding: 1rem !important;
    }
    .sm-screen-size{
        width: 100% !important;
    }
    .screen-size-40{
        width: 100% !important;
    }
    .screen-size-60{
        width: 100% !important;
    }
}
        

@media only screen and (max-width: 768px)  {
    .fyd{
        font-family: 'Jost';
        font-style: normal;
        font-weight: 800;
        font-size: 60px;
        top: 0%;
        /* line-height: 70px; */
        /* or 117% */
        text-align: center;
        color: #FFFFFF;
    }
    .paragraph{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 30px;
        /* or 150% */
        text-align: center;
        padding-left: 3%;
        padding-right: 3%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
    }
    .lg-screen-search{
        display: none;
    }
    .sm-screen-search{
        display: block;
        padding-top: 1rem;
    } 
    .mt-5 {
        margin-top: 2rem!important;
    } 
    .log-button{
        display: flex;
        justify-content: space-evenly;
    }
    .btn1 {
        background: transparent!important;
        color: #FFFFFF;
        border: 0.1rem solid #FFFFFF;
        border-radius: 0.3rem;
        padding: 2px 20px;
    }
    .btn2 {
        background: #1F53B9;
        color: #FFFFFF;
        border: 0.1rem solid #FFFFFF;
        border-radius: 0.3rem;
        padding: 2px 20px;
    }
    .hero-form {
        width: 100%!important;
        flex-direction: column!important;
        border-radius: 1rem;
        
      }
      .filter-margin {
        margin-top: 10rem;
        margin-right: 2rem !important;
        margin-bottom: 15rem;
        margin-left: 2rem!important;
    }
    .home-margin{
        margin-left: 0 !important;
        margin-right: 0 !important;
        
    }
}   
    