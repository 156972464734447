.about-content{
    width: 100%;
    height: 16rem;
    background-image: url(../../assets/image/About/about_cover.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #ffffff;
    text-align: center;
    margin-top: 4.5rem;

}
.about-title{
  font-family: 'poppins';
  font-size: 50px;
  font-weight: 600;
  margin-top: -1rem;
}
.about-msg{
  font-family: 'poppins';
  font-size: 18px;
  font-weight: 300;
  padding-inline: 25%;
}
.flex-shrink-2 {
    flex-shrink: 5!important;
}
.parent {
    position: relative;

  }
  .image{
    width: 15%;
    height: 15%;
  }

  @media only screen and (max-width: 375px) {
    .about-content{
      width: 100%;
      height: 10rem;
      background-image: url(../../assets/image/About/about_cover.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #ffffff;
      text-align: center;
      margin-top: 4.5rem;
  
  }
  .about-title{
    font-family: 'poppins';
    font-size: 30px;
    font-weight: 700;
    margin-top: -1rem;
  }
  .about-msg{
    font-family: 'poppins';
    font-size: 14px;
    font-weight: 300;
    padding-inline: 5%;
  }
    .contact-margin{
        margin-left: 0% !important;
        margin-right: 0% !important;
    }

}