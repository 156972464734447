.service-content{
    width: 100%;
    height: 15rem;
    background-image: url(../../assets/image/Service/service_cover.png);
    background-size: 100% 90%;
    background-repeat: no-repeat;
    color: #ffffff;
    text-align: center;
}
.properties-content{
    width: 100%;
    height: 15rem;
    background-image: url(../../assets/image/Others/latest_property_cover.png);
    background-size: 100% 90%;
    background-repeat: no-repeat;
    color: #ffffff;
    text-align: center;
}
