.home-margin{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 3%;
    
}
.bg{
    background-color: #F4F7FD;
}
.margin-bottom{
   
    margin-bottom: 3rem;
}
.home-scroll{
    overflow-y: scroll;
}
.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }