.hero-form-wrapper {
    margin-block-start: -120px;
    width: 65%;
    margin-inline: auto;
    overflow: hidden;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 5px 5px 50px 0px rgba(0, 115, 225, 0.15)!important;
  }

  .form-tab {
    display: flex;
    justify-content: center;
 
  }
  .btn-radius1{
    border-top-left-radius: 1rem;
    
  }
  .btn-radius2{
   
    border-top-right-radius: 1rem;
  }

  .tab-btn {
    padding: 0.8rem 2.4rem;
    color: black;
    background-color: #ffffff;
    font-family: 'poppins';
    font-size: 18px;
    font-weight: 500;
    transition: var(--transition);
    border: none;
    transition-timing-function: linear;
  }
  .form-tab:hover > .tab-btn {
    color: #1F53B9;
  }
  /* .tab-btn:is(:hover, :focus) { color: #1F53B9; } */
  
  .tab-btn.active {
    color: white;
    background-color: #1F53B9;
  }
  
  .hero-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 25px;
    background: #ffffff;
    padding: 25px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
   
    
  }
  
  .hero-form .input-wrapper { 
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 } 
  .hero-form :is(.input-label, .input-field) { color: var(--oxford-blue); }
  
  .hero-form .input-label {
    font-family: 'poppins';
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    margin-block-end: 10px;
  }
  
  .hero-form :is(.input-field, .dropdown-list) {
    min-height: 40px;
    border: 1px solid var(--gainsboro);
    border-radius: var(--radius-4);
    outline: none;
    transition: var(--transition);
  }
  
  .hero-form :is(.input-field, .dropdown-list):focus { border-color: var(--green-pigment); }
  
  .hero-form .input-field { padding-inline: 45px 15px; }
  
  .hero-form .input-field::placeholder { color: var(--manatee); }
  
  .hero-form .dropdown-list { 
    padding-block: 2px;
    padding-inline: 20px;
    font-family: 'poppins'; 
    font-size: 14px; 
    font-weight: 400;
    color:rgba(88, 88, 88, 1);
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 1.2rem;
   }
  
  .hero-form .btn { max-width: 100%; }
  .search-btn{
    font-size: 16px;
    background-color: #1F53B9;
    color: #ffffff;
    border: none;
    border-radius: 0.5rem;
  }
.search-btn:hover{
  font-size: 16.5px;
}

