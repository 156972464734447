.blog-content{
    width: 100%;
    height: 16rem;
    background-image: url(../../assets/image/BlogImg/blog_page_cover.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #ffffff;
    text-align: center;
    margin-top: 4.5rem;
}
.blog-title{
  font-family: 'poppins';
  font-size: 50px;
  font-weight: 600;
  margin-top: -1rem;
}
.blog-msg{
  font-family: 'poppins';
  font-size: 18px;
  font-weight: 300;
  padding-inline: 25%;
}
.blog-margin{
    margin-left: 30% !important;
    margin-right: 30% !important;
}
.flex-shrink-1 {
    flex-shrink: 2!important;
}
.latestPropertyCard{    
        padding: 0 !important; 
}


@media only screen and (max-width: 375px) {
    .blog-content{
      width: 100%;
      height: 10rem;
      background-image: url(../../assets/image/BlogImg/blog_page_cover.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #ffffff;
      text-align: center;
      margin-top: 4.5rem;
  
  }
  .blog-title{
    font-family: 'poppins';
    font-size: 30px;
    font-weight: 700;
    margin-top: -1rem;
  }
  .blog-msg{
    font-family: 'poppins';
    font-size: 13px;
    font-weight: 300;
    padding-inline: 5%;
  }
    .blog-margin{
        margin-left: 1% !important;
        margin-right: 1% !important;
    }

}