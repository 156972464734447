.bg-color{
    background: rgba(36, 50, 74, 1);
}
.top-line{
    border-top: 0.1rem solid rgba(255, 255, 255, 0.657);
}
.footer-padding{
    padding-top: 3%;
    padding-left: 6%;
    padding-right: 6%;
}
.footer-title{
    font-family: 'poppins';
    font-size: 20px;
    font-weight: 500;

}
.title-info{
    font-family: 'poppins';
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    color: rgba(205, 205, 205, 1);

}
.gap-10{
    gap:"30px";
}
.rs-rights{
    font-family: 'poppins';
    font-size: 12px;
    font-weight: 400;
    color: rgba(205, 205, 205, 1);
}
.follow-us-width{
    width: 12%;
}
.contact-us-width{
    width: 20%;
}
@media only screen and (max-width: 375px) {
    .follow-us-width{
        width: 100% !important;
    }
    .contact-us-width{
        width: 100% !important;
    }
}