
.blog-card-margin{
  margin-left: 10rem;
  margin-right: 10rem;
  margin-bottom: 10rem;
}
.blog-padding{
  padding-right: 5rem!important;
  padding-left: 5rem!important;
  padding-bottom: 5rem !important;
}

.lorem-padding{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.box-shaddow{
  box-shadow: 5px 5px 50px rgba(0, 115, 225, 0.15);
}
