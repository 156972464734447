body {
  margin: 0;
  font-family: 'poppins';
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  overflow-x: hidden;
}
body::-webkit-scrollbar{
  display: none;
}
code {
  font-family: 'poppins';
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
.active-nav{
  color: #1F53B9;
}