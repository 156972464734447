.logo1{
  width: 9rem !important;
  height: 2rem !important;
}
  
  .Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.9;
    position: fixed;
  }
  
  .Auth-form {
    width: 35rem;
    box-shadow: rgb(0 0 0 / 50%) 10px 10px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    
    
  }
  
  .Auth-form-content {
    width: 80%;
  }
  
  .Auth-form-title {
    text-align: center;
    margin-top: 1rem;
    font-size: 1.5rem;
  }
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
  }
  .username{
    background: rgba(0, 0, 0, 0.3);
  }
  .password{
    background: rgba(0, 0, 0, 0.3);
  }
  .forget-psw{
    color: red;
    font-size: 0.9rem;
  }
  .registration{
    color: #1F53B9;
    
  }
  .forgot-password{
    color: red;   
  }

.circle{
  border: 1px solid #111111;
  border-radius: 100%;
}