a, a:hover{
    text-decoration: none;
}
.searchBox{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
   
}
.searchText{
    width: 0rem;
   
    border-color: #1F53B9;
    border-radius: 15rem;
    transition: all 0.2s linear;

}
.searchText:focus{
    outline: none;
    

}
.searchBtn{
    width: 2rem;
    height: 2rem;
    background-color: #1F53B9;
    color: #FFFFFF;
    position: absolute;
    padding-right: 5%;
    right: 0.5rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
}
.searchBox:hover > .searchText{
    width: 12rem;
    height: 2rem;
    padding: 0.1rem;

}
.searchBox:hover > .searchBtn{
    background: #FFFFFF;
    color: royalblue;
    width: 1rem;
    height: 1rem;
    right: 2rem;

}