
.img-width{
    padding-left: 4rem;
    padding-right: 4rem;
    width: 18rem;
}
@media only screen and (max-width: 375px){
    .img-width{
        padding-left: 0rem !important;
        padding-right: 0rem !important;
        width: 10rem !important;
        
    }

}
@media only screen and (max-width: 1024px){
    .img-width{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        width: 12rem !important;
        
    }

}