
.contact-margin{
    margin-left: 10%;
    margin-right: 10%;
}

.contact-content{
    width: 100%;
    height: 16rem;
    background-image: url(../../assets/image/Others/contact_Img.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #ffffff;
    text-align: center;
    margin-top: 4.5rem;

}
.contact-title{
    font-family: 'poppins';
    font-size: 50px;
    font-weight: 600;
    margin-top: -2rem;
  }

.textarea{
    border: none;
    border-radius: 0.2rem;
    
}

.contact-message{
   
    font-family: "Poppins";
    font-size: 60px;
    font-weight: 600;
    line-height: 5rem;
    
} 
.contact-sub-msg{
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400; 
    color: rgba(88, 88, 88, 1);
}
@media only screen and (max-width: 375px) {
    .contact-margin{
        margin-left: 2% !important;
        margin-right:2% !important;
    }
    .contact-content{
        width: 100%;
        height: 10rem;
        background-image: url(../../assets/image/Others/contact_Img.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #ffffff;
        text-align: center;
        margin-top: 4.5rem;
    
    }
    .contact-title{
        font-family: 'poppins';
        font-size: 30px;
        font-weight: 700;
        margin-top: -1rem;
      }
      .contact-message{
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 500;
        padding: 0% !important;
    }
    .contact-sub-msg{
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 300; 
        color: rgba(88, 88, 88, 1);
    }
    
    .p .p-5{
        padding: 0 !important;
    }

}
